<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="上传时间">
              <a-range-picker @change="time" format="YYYY-MM-DD HH:mm:ss">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <!--      <span slot="action" slot-scope="text, record" v-action:delete>
        <template>
          <a @click="handleDelet(record)">删除</a>
        </template>
      </span>-->
      <span slot="img" slot-scope="img, record">
        <a-avatar
          v-if="(getFileSuffix(img) !== 'pdf') && img"
          slot="img"
          :src="img"
          shape="square"
          @click="openPreview(img)"
        />
        <a :href="img" v-if="getFileSuffix(img) === 'pdf'" target="_blank">{{ record.name }}</a>
      </span>
    </s-table>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import { corporation_auth_list, corporation_auth_create, corporation_auth_delete } from '@/api/corporation_auth'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/UndertakingForm'
import { Drawer, Tag } from 'ant-design-vue'
/*
import pick from 'lodash.pick'
*/
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    Drawer,
    Tag
  },
  data () {
    return {
      previewvisible: false,
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      DrawerVisible: false,
      current_corporation_auth_id: null,
      // 创建窗口控制
      visible: false,
      tags: [],
      selectedTags: [],
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '上传时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '文件',
          dataIndex: 'img',
          scopedSlots: { customRender: 'img' }
        }/*,
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        } */
      ],
      previewurl: '',
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return corporation_auth_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return { entries: res.data }
          })
      }
    }
  },
  created () {
  },
  methods: {
    getFileSuffix: function (filename) {
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    checkFileType: function (suffix) {
      var type = ''
      switch (suffix) {
        case 'mp4':
          type = 'mp4'
          break
        case 'ppt':
        case 'pptx':
        case 'doc':
        case 'docx':
        case 'pdf':
          type = 'office'
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'bmp':
        case 'gif':
          type = 'pic'
          break
      }
      return type
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
      corporation_auth_delete(record.id).then((res) => {
        console.log(res)
        this.$refs.table.refresh()
      })
    },
    onCloseDrawer () {
      this.DrawerVisible = false
    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    handleOk (e) {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.img = values.entrance_img && (e.actionUrl + '/' + values.entrance_img) || ''
          values.corporation_id = this.$Dictionaries.corporation_id
          // 新增
          corporation_auth_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          }, (errors) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {}
    }
  }
}
</script>
